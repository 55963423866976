<template>
	<div class="event">
		<a
			:href="event.url"
			:target="event.open_url_in_another_window ? '_blank' : '_self'"
			class="event__pic"
			:style="event.image | bgrDynamicImageCover"
		>
			<div
				v-for="(section, index) in event.sections"
				:key="index"
				class="event__type"
			>{{ section }}</div>
		</a>
		<div class="event__content">
			<h3 class="event__title">
				{{ event.title }}
			</h3>
			<a
				:href="event.url"
				:target="event.open_url_in_another_window ? '_blank' : '_self'"
				class="more-link"
			>
				Подробнее
				<img
					:src="'/img/icons/icon__arrow-right--orange.svg' | prodSrcImage"
					alt="Иконка"
				>
			</a>
		</div>
	</div>
</template>

<script>
export default {
    name: 'EventCard',
    props: {
        event: {
            type: Object,
            default: null
        }
    }
};
</script>
