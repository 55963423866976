<template>
	<div class="content">
		<Breadcrumb :page-name="'Мероприятия'" />
		<div class="events">
			<h2 class="title-section">
				Практика и мероприятия
			</h2>
			<p class="events__text">
				Здесь мы собрали самые интересные и полезные активности «Лифта в будущее». Участвуя в них, ты не только получишь новые знания, но и получишь зачет в стипендиальной программе.
			</p>
			<div
				v-if="!projects"
				class="preloader"
				style="margin-top: 50px;"
			/>
			<div
				v-else
				class="events__list"
			>
				<EventCard
					v-for="item in projects"
					:key="item.id"
					:event="item"
				/>
			</div>
		</div>
	</div>
</template>

<script>
import Breadcrumb from '@/components/Breadcrumb';
import {mapActions, mapGetters} from 'vuex';
import EventCard from '@/components/events/EventCard';

export default {
    name: 'Projects',
    components: {
        Breadcrumb,
        EventCard
    },
    computed: {
        ...mapGetters('tb', {
            projects: 'getSpecialProjects'
        })
    },
    created() {
        this.getSpecialProjects()
    },
    methods: {
        ...mapActions('tb', [
            'getSpecialProjects'
        ])
    }
}
</script>

<style lang="less">
@import "../assets/styles/pages/events";
</style>
